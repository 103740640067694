import React from "react";
import { pageNext, pagePrev } from "../../assets/svg/svg";

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const incr = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decr = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePagination = () => {
    if (totalPages <= 3) {
      const paginationButtons = [];

      for (let i = 1; i <= totalPages; i++) {
        paginationButtons.push(
          <div
            key={i}
            className={`pagi_btn ${currentPage === i ? "pagi_btn_active" : ""}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </div>
        );
      }
  
      // Return the array of JSX elements
      return paginationButtons;

      // <div className={`pagi_btn ${currentPage === 2 ? "pagi_btn_active" : ""}`} onClick={() => handlePageChange(2)}>
      //   2
      // </div>
      // <div className={`pagi_btn ${currentPage === 3 ? "pagi_btn_active" : ""}`} onClick={() => handlePageChange(3)}>
      //   3
      // </div>
    }
    if (totalPages > 3 && currentPage <= 2) {
      return (
        <>
          <div className={`pagi_btn ${currentPage === 1 ? "pagi_btn_active" : ""}`} onClick={() => handlePageChange(1)}>
            1
          </div>
          <div className={`pagi_btn ${currentPage === 2 ? "pagi_btn_active" : ""}`} onClick={() => handlePageChange(2)}>
            2
          </div>
          <span></span>
          <span></span>
          <div
            className={`pagi_btn ${currentPage === totalPages} ? "pagi_btn_active" : ""}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </div>
        </>
      );
    }

    if (totalPages > 3 && currentPage < totalPages - 1) {
      return (
        <>
          <div className={`pagi_btn ${currentPage === 1 ? "pagi_btn_active" : ""}`} onClick={() => handlePageChange(1)}>
            1
          </div>
          <span></span>
          <div
            className={`pagi_btn ${currentPage === currentPage ? "pagi_btn_active" : ""}`}
            onClick={() => handlePageChange(currentPage)}
          >
            {currentPage}
          </div>
          <span></span>{" "}
          <div
            className={`pagi_btn ${currentPage === totalPages ? "pagi_btn_active" : ""}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </div>
        </>
      );
    }

    if (totalPages > 3 && (currentPage === totalPages || currentPage === totalPages - 1)) {
      return (
        <>
          <div className={`pagi_btn ${currentPage === 1 ? "pagi_btn_active" : ""}`} onClick={() => handlePageChange(1)}>
            1
          </div>
          <span></span>
          <span></span>
          <div
            className={`pagi_btn ${currentPage === totalPages - 1 ? "pagi_btn_active" : ""}`}
            onClick={() => handlePageChange(totalPages - 1)}
          >
            {totalPages - 1}
          </div>
          <div
            className={`pagi_btn ${currentPage === totalPages ? "pagi_btn_active" : ""}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </div>
        </>
      );
    }
  };

  return (
    <div className="pagination ms-auto w-auto">
      <div className="arrow_prev" onClick={decr}>
        {pagePrev}
      </div>
      {handlePagination()}
      <div className="arrow_next" onClick={incr}>
        {pageNext}
      </div>{" "}
    </div>
  );
};

export default Pagination;

//   // * Pagination:-
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   // Rendering Conditionally pagination Items
//   const renderPaginationItems = () => {
//     const items = [];

//     // if Only 3 Pages Then
//     if (totalPages === 3) {
//       for (let page = 1; page <= Math.min(totalPages, 3); page++) {
//         items.push(
//           <div
//             key={page}
//             className={`pagi_btn ${page === currentPage ? "pagi_btn_active" : ""}`}
//             onClick={() => handlePageChange(page)}
//           >
//             <span>{page}</span>
//           </div>
//         );
//       }
//       return items;
//     }

//     // This is the pattern of 12..totalItems
//     if (currentPage <= 2) {
//       for (let page = 1; page <= Math.min(totalPages, 2); page++) {
//         items.push(
//           <div
//             key={page}
//             className={`pagi_btn ${page === currentPage ? "pagi_btn_active" : ""}`}
//             onClick={() => handlePageChange(page)}
//           >
//             <span>{page}</span>
//           </div>
//         );
//       }
//       if (totalPages > 2) {
//         items.push(<span key="ellipsisEnd1"></span>);
//         items.push(<span key="ellipsisEnd1"></span>);
//         items.push(
//           <div
//             key={totalPages}
//             className={`pagi_btn ${totalPages === currentPage ? "pagi_btn_active" : ""}`}
//             onClick={() => handlePageChange(totalPages)}
//           >
//             <span>{totalPages}</span>
//           </div>
//         );
//       }
//       return items;
//     }

//     // This is Pattern Of 2: 1...7...totalItems
//     // console.log(3 < 5 - 2);
//     if (currentPage <= totalPages - 2) {
//       items.push(
//         <div
//           key={1}
//           className={`pagi_btn ${currentPage === 1 ? "pagi_btn_active" : ""}`}
//           onClick={() => handlePageChange(1)}
//         >
//           <span>{1}</span>
//         </div>
//       );
//       items.push(<span key="ellipsisStart2"></span>);
//       const centerPage = Math.min(totalPages - 1, currentPage);
//       items.push(
//         <div
//           key={centerPage}
//           className={`pagi_btn ${centerPage === currentPage ? "pagi_btn_active" : ""}`}
//           onClick={() => handlePageChange(centerPage)}
//         >
//           <span>{centerPage}</span>
//         </div>
//       );
//       items.push(<span key="ellipsisEnd2"></span>);
//       items.push(
//         <div
//           key={totalPages}
//           className={`pagi_btn ${totalPages === currentPage ? "pagi_btn_active" : ""}`}
//           onClick={() => handlePageChange(totalPages)}
//         >
//           <span>{totalPages}</span>
//         </div>
//       );
//       return items;
//     }

//     // 3rd Pattern: 1..9totalItems
//     if (currentPage <= totalPages) {
//       items.push(
//         <div
//           key={1}
//           className={`pagi_btn ${currentPage === 1 ? "pagi_btn_active" : ""}`}
//           onClick={() => handlePageChange(1)}
//         >
//           <span>{1}</span>
//         </div>
//       );
//       if (totalPages > 2) {
//         items.push(<span key="ellipsisStart3"></span>);
//         items.push(<span key="ellipsisStart3"></span>);
//         items.push(
//           <div
//             key={totalPages - 1}
//             className={`pagi_btn ${currentPage === totalPages - 1 ? "pagi_btn_active" : ""}`}
//             onClick={() => handlePageChange(totalPages - 1)}
//           >
//             <span>{totalPages - 1}</span>
//           </div>
//         );
//         items.push(
//           <div
//             key={totalPages}
//             className={`pagi_btn ${totalPages === currentPage ? "pagi_btn_active" : ""}`}
//             onClick={() => handlePageChange(totalPages)}
//           >
//             <span>{totalPages}</span>
//           </div>
//         );
//       }
//       return items;
//     }
//   };
