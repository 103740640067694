import * as staticActions from "./staticAction";
export const staticFeatureKey = "staticPageInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  pageData:{},
  contactPage:{}
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case staticActions.GET_ABOUT_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_ABOUT_PAGE_SUCCESS:
      return {
        ...state,
        pageData: payload?.data,
        loading: false,
      };
    case staticActions.GET_ABOUT_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      // contact us:
      case staticActions.GET_CONTACT_US_PAGE_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case staticActions.GET_CONTACT_US_PAGE_SUCCESS:
        return {
          ...state,
          contactPage: payload?.data,
          loading: false,
        };
      case staticActions.GET_CONTACT_US_PAGE_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };

    default:
      return state;
  }
};
