import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Check payment status:
export const GET_PAYMENT_STATUS_REQUEST = "GET_PAYMENT_STATUS_REQUEST";
export const GET_PAYMENT_STATUS_SUCCESS = "GET_PAYMENT_STATUS_SUCCESS";
export const GET_PAYMENT_STATUS_FAILURE = "GET_PAYMENT_STATUS_FAILURE";

// Get payment selection:
export const GET_PAYMENT_SELECTION_REQUEST = "GET_PAYMENT_SELECTION_REQUEST";
export const GET_PAYMENT_SELECTION_SUCCESS = "GET_PAYMENT_SELECTION_SUCCESS";
export const GET_PAYMENT_SELECTION_FAILURE = "GET_PAYMENT_SELECTION_FAILURE";

export const checkPaymentStatus = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_STATUS_REQUEST });
      const url = `${BASE_URL}/phonepe/user/check/status/phonepe/payment/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // alert(JSON.stringify( response.data ))
      dispatch({ type: GET_PAYMENT_STATUS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PAYMENT_STATUS_FAILURE, payload: error.message });
    }
  };
};

export const getPaymentSelection = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_SELECTION_REQUEST });
      const url = `${BASE_URL}/phonepe/user/payment/selection/get/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      // alert(JSON.stringify( response.data ))
      dispatch({ type: GET_PAYMENT_SELECTION_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PAYMENT_SELECTION_FAILURE, payload: error.message });
    }
  };
};
