import * as vidioActions from "./vidioAction";
export const vidioFeatureKey = "vidioInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allVidios: [],
  allSuggestionVidios: [],
  suggestionLoading: false,
  suggestionVidioCount: undefined,
  vidioCount: undefined,
  rtcToken: null,
  videoAllComments: [],
  totalCommentCount:undefined
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case vidioActions.GET_ALL_VIDIOS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vidioActions.GET_ALL_VIDIOS_SUCCESS:
      return {
        ...state,
        allVidios: payload?.data,
        vidioCount: payload?.vidioCount[0]?.vidioCount,
        loading: false,
      };
    case vidioActions.GET_ALL_VIDIOS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Update videos
    case vidioActions.UPDATE_VIDEO_VIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vidioActions.UPDATE_VIDEO_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case vidioActions.UPDATE_VIDEO_VIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Suggestion vidios
    case vidioActions.GET_ALL_SUGGESTION_VIDIOS_REQUEST:
      return {
        ...state,
        suggestionLoading: true,
      };

    case vidioActions.GET_ALL_SUGGESTION_VIDIOS_SUCCESS:
      return {
        ...state,
        allSuggestionVidios: payload?.data,
        suggestionVidioCount: payload?.vidioCount[0]?.vidioCount,
        suggestionLoading: false,
      };
    case vidioActions.GET_ALL_SUGGESTION_VIDIOS_FAILURE:
      return {
        ...state,
        suggestionLoading: false,
        errorMsg: payload,
      };

    // videoToken

    case vidioActions.GET_AGORE_VIDEOS_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        rtcToken: null,
      };

    case vidioActions.GET_AGORE_VIDEOS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        rtcToken: payload.rtcToken,
      };
    case vidioActions.GET_AGORE_VIDEOS_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        rtcToken: null,
      };

    // Comment on video:
    case vidioActions.COMMENT_ON_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vidioActions.COMMENT_ON_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case vidioActions.COMMENT_ON_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get all comments:
    case vidioActions.GET_ALL_COMMENTS_OF_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vidioActions.GET_ALL_COMMENTS_OF_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        videoAllComments: payload.data,
        totalCommentCount: payload.totalComments
      };
    case vidioActions.GET_ALL_COMMENTS_OF_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Like video
    case vidioActions.LIKE_VIDEO_REQUEST:
      return {
        ...state,
        // loading: true,
      };

    case vidioActions.LIKE_VIDEO_SUCCESS:
      return {
        ...state,
        // loading: false,
      };
    case vidioActions.LIKE_VIDEO_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
      };

      // update comment 
      case vidioActions.UPDATE_VIDEO_COMMENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case vidioActions.UPDATE_VIDEO_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case vidioActions.UPDATE_VIDEO_COMMENT_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };
  
        

        // delete comment 
        case vidioActions.DELETE_VIDEO_COMMENT_REQUEST:
          return {
            ...state,
            loading: true,
          };
    
        case vidioActions.DELETE_VIDEO_COMMENT_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case vidioActions.DELETE_VIDEO_COMMENT_FAILURE:
          return {
            ...state,
            loading: false,
            errorMsg: payload,
          };
    
    default:
      return state;
  }
};
