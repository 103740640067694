import * as addressActions from "./addressAction";
export const addressFeatureKey = "addressInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  address: [],
  addressDetails: {},
  isAddressUpdateStatus: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case addressActions.ADD_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case addressActions.ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAddress: payload?.data,
        isAddressUpdateStatus: true,
      };
    case addressActions.ADD_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // edit

    case addressActions.EDIT_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case addressActions.EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleAddress: payload?.data,
        isAddressUpdateStatus: true,
      };
    case addressActions.EDIT_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    // address list
    case addressActions.ADDRESS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case addressActions.ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        address: payload?.data,
      };
    case addressActions.ADDRESS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get address details
    case addressActions.SINGLE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case addressActions.SINGLE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addressDetails: payload?.data,
      };
    case addressActions.SINGLE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case "IS_ADDRESS_UPDATE":
      return {
        ...state,
        isAddressUpdateStatus: false,
      };
    case addressActions.DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case addressActions.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAddressUpdateStatus: true,
      };
    case addressActions.DELETE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
