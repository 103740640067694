import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// GET all booking prescrption:
export const GET_ALL_BOOKING_PRESCRIPTION_LIST_REQUEST = "GET_ALL_BOOKING_PRESCRIPTION_LIST_REQUEST";
export const GET_ALL_BOOKING_PRESCRIPTION_LIST_SUCCESS = "GET_ALL_BOOKING_PRESCRIPTION_LIST_SUCCESS";
export const GET_ALL_BOOKING_PRESCRIPTION_LIST_FAILURE = "GET_ALL_BOOKING_PRESCRIPTION_LIST_FAILURE";

// Get all prescriptions:
export const getAllPrescriptions = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_BOOKING_PRESCRIPTION_LIST_REQUEST });
      const url = `${BASE_URL}/prescription/get/all/booking/prescription/for/user/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_ALL_BOOKING_PRESCRIPTION_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_BOOKING_PRESCRIPTION_LIST_FAILURE,
        payload: error.message,
      });
    }
  };
};
