import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Add Order
export const SUBSCRIBE_NEWSLETTER_REQUEST = "SUBSCRIBE_NEWSLETTER_REQUEST";
export const SUBSCRIBE_NEWSLETTER_SUCCESS = "SUBSCRIBE_NEWSLETTER_SUCCESS";
export const SUBSCRIBE_NEWSLETTER_FAILURE = "SUBSCRIBE_NEWSLETTER_FAILURE";

export const subscribeNewsLetter = (email, setEmail) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SUBSCRIBE_NEWSLETTER_REQUEST });
            const url = `${BASE_URL}/news/letter/subscibe/v1`;
            const response = await axios.post(url, { email });
            dispatch({ type: SUBSCRIBE_NEWSLETTER_SUCCESS, payload: response?.data });
            setEmail("");
            toast("Newsletter Subscribed successfully");
        } catch (error) {
            console.error(error);
            dispatch({ type: SUBSCRIBE_NEWSLETTER_FAILURE, payload: error.message });
            if (error?.response?.data?.message) {
                toast(error.response.data.message);
            }
        }
    };
};