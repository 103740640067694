import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_ABOUT_PAGE_REQUEST = "GET_ABOUT_PAGE_REQUEST";
export const GET_ABOUT_PAGE_SUCCESS = "GET_ABOUT_PAGE_SUCCESS";
export const GET_ABOUT_PAGE_FAILURE = "GET_ABOUT_PAGE_FAILURE";

export const GET_CONTACT_US_PAGE_REQUEST = "GET_CONTACT_US_PAGE_REQUEST";
export const GET_CONTACT_US_PAGE_SUCCESS = "GET_CONTACT_US_PAGE_SUCCESS";
export const GET_CONTACT_US_PAGE_FAILURE = "GET_CONTACT_US_PAGE_FAILURE";

export const getAboutPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ABOUT_PAGE_REQUEST });
      const url = `${BASE_URL}/static/about/page/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: GET_ABOUT_PAGE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ABOUT_PAGE_FAILURE, payload: error.message });
    }
  };
};

export const getContactUsPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONTACT_US_PAGE_REQUEST });
      const url = `${BASE_URL}/static/contact/page/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: GET_CONTACT_US_PAGE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_CONTACT_US_PAGE_FAILURE, payload: error.message });
    }
  };
};