import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_PET_CATEGORY_REQUEST = "GET_PET_CATEGORY_REQUEST";
export const GET_PET_CATEGORY_SUCCESS = "GET_PET_CATEGORY_SUCCESS";
export const GET_PET_CATEGORY_FAILURE = "GET_PET_CATEGORY_FAILURE";

export const getPetCategoryList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PET_CATEGORY_REQUEST });
      const url = `${BASE_URL}/pet/admin/get/all/pet/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_PET_CATEGORY_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PET_CATEGORY_FAILURE, payload: error.message });
    }
  };
};
