import * as petActions from "./petAction";
export const petFeatureKey = "petsInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  myPets: [],
  petBreeds: [],
  createdPet: {},
  myPetDetails: {},
  alergiesDropdown: [],
  eHealthCardPets: [],
  myGovPets: [],
  singleGovPets: {},
  createGovermentPet: {},
  isPetUpdate: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case petActions.GET_MY_PETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_MY_PETS_SUCCESS:
      return {
        ...state,
        loading: false,
        myPets: payload?.data,
      };
    case petActions.GET_MY_PETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get single pet
    case petActions.GET_SINGLE_PET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_SINGLE_PET_SUCCESS:
      return {
        ...state,
        loading: false,
        myPetDetails: payload?.data,
      };
    case petActions.GET_SINGLE_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Update My pet Info
    case petActions.UPDATE_MY_PET_REQUEST:
      return {
        ...state,
        loading: true,
        isPetUpdate: false,
      };

    case petActions.UPDATE_MY_PET_SUCCESS:
      return {
        ...state,
        loading: false,
        isPetUpdate: true,
        // myPets: payload?.data,
      };
    case petActions.UPDATE_MY_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // create Pet
    case petActions.CREATE_PET_REQUEST:
      return {
        ...state,
        loading: true,
        isPetUpdate: false,
      };

    case petActions.CREATE_PET_SUCCESS:
      return {
        ...state,
        loading: false,
        createdPet: payload,
        isPetUpdate: true,
      };
    case petActions.CREATE_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // create E healthcard
    case petActions.CREATE_EHEALTHCARD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.CREATE_EHEALTHCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        createdPet: payload.data,
      };
    case petActions.CREATE_EHEALTHCARD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case petActions.GET_PET_BREED_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_PET_BREED_SUCCESS:
      return {
        ...state,
        loading: false,
        petBreeds: payload?.data,
      };
    case petActions.GET_PET_BREED_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // pet Allergies Dropdown
    case petActions.GET_PET_ALERGIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_PET_ALERGIES_SUCCESS:
      return {
        ...state,
        loading: false,
        alergiesDropdown: payload?.data,
      };
    case petActions.GET_PET_ALERGIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // e-healthcard pets
    case petActions.GET_MY_EHEALTHCARD_PETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_MY_EHEALTHCARD_PETS_SUCCESS:
      return {
        ...state,
        loading: false,
        eHealthCardPets: payload?.data,
      };
    case petActions.GET_MY_EHEALTHCARD_PETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //  create goverment pet
    case petActions.CREATE_GOVERMENT_PET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.CREATE_GOVERMENT_PET_SUCCESS:
      return {
        ...state,
        loading: false,
        createGovermentPet: payload,
      };
    case petActions.CREATE_GOVERMENT_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete Pet
    case petActions.DELETE_PET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.DELETE_PET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case petActions.DELETE_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get Goverment pets
    case petActions.GET_MY_GOVERMENT_PETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_MY_GOVERMENT_PETS_SUCCESS:
      return {
        ...state,
        loading: false,
        myGovPets: payload?.data,
      };
    case petActions.GET_MY_GOVERMENT_PETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update Goverment pet
    case petActions.UPDATE_MY_GOVERMENT_PET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.UPDATE_MY_GOVERMENT_PET_SUCCESS:
      return {
        ...state,
        loading: false,
        // myGovPets: payload?.data,
      };
    case petActions.UPDATE_MY_GOVERMENT_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get my goverment pet details
    case petActions.GET_MY_GOVERMENT_PET_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.GET_MY_GOVERMENT_PET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        singleGovPets: payload?.data,
      };
    case petActions.GET_MY_GOVERMENT_PET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // gdelete My goverment pets
    case petActions.DELETE_MY_GOVERMENT_PET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.DELETE_MY_GOVERMENT_PET_SUCCESS:
      return {
        ...state,
        loading: false,
        // myGovPets: payload?.data,
      };
    case petActions.DELETE_MY_GOVERMENT_PET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
