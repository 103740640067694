import * as orderAction from "./orderAction";

export const orderFeatureKey = "orderInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allOrders: [],
  singleOrder: {},
  createOrder: {},
  cancelDropdown: [],
  getDiscountAndChargesData:{}
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Get All Orders
    case orderAction.GET_ALL_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.GET_ALL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        allOrders: payload,
      };

    case orderAction.GET_ALL_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get Single Order
    case orderAction.GET_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.GET_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        singleOrder: payload?.data,
        loading: false,


      };

    case orderAction.GET_SINGLE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Add Order
    case orderAction.ADD_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.ADD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        createOrder: payload,
      };

    case orderAction.ADD_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Update Order
    // Add Order
    case orderAction.UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case orderAction.UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get All Cancel Reasons
    case orderAction.GET_ALL_CANCEL_REASONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.GET_ALL_CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelDropdown: payload?.data,
      };

    case orderAction.GET_ALL_CANCEL_REASONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
      

      
    // Get dicount and charges data
    case orderAction.GET_DISCOUNT_AND_DELIVERY_CHARGES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.GET_DISCOUNT_AND_DELIVERY_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        getDiscountAndChargesData: payload?.data,
      };

    case orderAction.GET_DISCOUNT_AND_DELIVERY_CHARGES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
