import * as petReportAbuseActions from "./petReportAbuseAction";
export const petReportAbuseFeatureKey = "petReportAbuseInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allAbuseResons: [],
  vidioPercentage: "",
  getReportAbuseData: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case petReportAbuseActions.UPDATE_VIDEO_UPLOAD_PROGRESS:
      return {
        ...state,
        loading: false,
        vidioPercentage: payload,
      };

    // Get report abuse details:
    case petReportAbuseActions.UPDATE_INCIDENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petReportAbuseActions.UPDATE_INCIDENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case petReportAbuseActions.UPDATE_INCIDENT_DETAILS_FAILURE:
      return {
        ...state,
        errorMsg: payload,
        loading: false,
      };

    // Get report abuse details:
    case petReportAbuseActions.GET_REPORT_ABUSE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petReportAbuseActions.GET_REPORT_ABUSE_DETAILS_SUCCESS:
      return {
        ...state,
        getReportAbuseData: payload.data,
        loading: false,
      };
    case petReportAbuseActions.GET_REPORT_ABUSE_DETAILS_FAILURE:
      return {
        ...state,
        errorMsg: payload,
        loading: false,
      };

    // Create pet abuse details form:
    case petReportAbuseActions.CREATE_INCIDENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petReportAbuseActions.CREATE_INCIDENT_DETAILS_SUCCESS:
      localStorage.setItem("abuseId", JSON.stringify(payload?.data?._id));
      return {
        ...state,
        loading: false,
      };
    case petReportAbuseActions.CREATE_INCIDENT_DETAILS_FAILURE:
      return {
        ...state,
        errorMsg: payload,
        loading: false,
      };

    // Create perpetrator details Form:
    case petReportAbuseActions.CREATE_PERPRATOR_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petReportAbuseActions.CREATE_PERPRATOR_DETAILS_SUCCESS:
      payload?.data?._id && localStorage.setItem("perpetratorId", JSON.stringify(payload?.data?._id));
      return {
        ...state,
        loading: false,
      };
    case petReportAbuseActions.CREATE_PERPRATOR_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Create witness details form:
    case petReportAbuseActions.CREATE_WITNESS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petReportAbuseActions.CREATE_WITNESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case petReportAbuseActions.CREATE_WITNESS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get all Abuse Types Dropdown
    case petReportAbuseActions.GET_ALL_ABUSE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petReportAbuseActions.GET_ALL_ABUSE_TYPES_SUCCESS:
      return {
        ...state,
        allAbuseResons: payload.data,
        loading: false,
      };
    case petReportAbuseActions.GET_ALL_ABUSE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
