import React from "react";
import { Col, Spinner } from "react-bootstrap"; // Import the necessary Bootstrap component

const Loader = ({ height }) => {
  return (
    <Col md={12} className="d-flex justify-content-center align-items-center mt-3" style={{ height: height }}>
            <Spinner animation="border" className="loader_primary" />
    </Col>
  );
};

export default Loader;
