import React, { useEffect, useState } from "react";
import ReferEarnImg from "../assets/svg/refer_and_earn.svg";
import facebookLogo from "../assets/images/facebook.png";
import whatsappLogo from "../assets/images/whatsapp.jpg";
import twitterLogo from "../assets/images/tweeter_image.png";
import linkedinLogo from "../assets/images/linkdin.png";
import CopyImg from "../assets/svg/copy_icon.svg";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import { Col, FormLabel, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as referActions from "../redux/refer/referAction";
import { toast } from "react-toastify";

export default function ReferInputScreen() {
  const [copied, setCopied] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.userDetails);
  const [referId, setReferId] = useState();

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, [3000]);
  }, [copied]);

  const handleInviteClick = () => {
    setShowShare(!showShare);
  };

  const handleVerifyReferId = () => {
    if (!referId) {
      toast("Please enter Refer Code");
      return;
    }
    dispatch(referActions.verifyUserReferId(referId, navigate));
    // navigate("/");
  };

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && !user?.data?.isNewUser) {
      navigate("/");
    }
  }, [user, navigate]);
  return (
    <section className="refer_earn">
      <div className="container">
        <div className="refer_earn_wrapper">
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <div className="main_img">
                <img src={ReferEarnImg} alt="refer and earn" />
              </div>
            </div>
            <div className="col-md-12 col-xl-12">
              <div className="refer_text">
                <div className="title">Refer Code</div>
                <div className="content">”Enter Refer code and get reward”</div>
              </div>
            </div>
            <div className="col-md-12 col-xl-12">
              <div className="code_container">
                <div className="code_wrapper">
                  {/* <div className="code"> */}
                  {/* <div className="code_content"> */}
                  <label htmlFor="referCode" className="form-label" style={{ fontWeight: 600 }}>
                    Refer code
                  </label>
                  <input
                    className="code"
                    type="text"
                    name="referId"
                    value={referId}
                    onChange={(e) => {
                      setReferId(e.target.value);
                    }}
                    // readOnly // Ensure the input is read-only if you don't want users to change the code
                  />
                  {/* </div> */}
                  {/* </div> */}
                  <Row>
                    <Col>
                      <div className="action">
                        <button onClick={handleVerifyReferId}>Submit</button>
                      </div>{" "}
                    </Col>
                    <Col>
                      <div className="action">
                        <button onClick={() => navigate("/")}>Skip</button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
