import * as newsLetterActions from "./newsLetter.actions";

export const newsLetterFeatureKey = "newsLetterInfo";

const initialState = {
    loading: false,
    errorMsg: ""
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case newsLetterActions.SUBSCRIBE_NEWSLETTER_REQUEST:
            return {
                ...state,
                loading: true
            };

        case newsLetterActions.SUBSCRIBE_NEWSLETTER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case newsLetterActions.SUBSCRIBE_NEWSLETTER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            };

        default: return state;
    }
};