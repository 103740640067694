import * as prescriptionActions from "./prescriptionAction";
export const prescriptionFeatureKey = "prescriptionInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allPrescriptions: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case prescriptionActions.GET_ALL_BOOKING_PRESCRIPTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case prescriptionActions.GET_ALL_BOOKING_PRESCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allPrescriptions: payload.data,
      };
    case prescriptionActions.GET_ALL_BOOKING_PRESCRIPTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
