import * as vetDropdownActions from "./vetDropdownAction";
export const vetDropdownFeatureKey = "vetDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  pet_concerns: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case vetDropdownActions.GET_PET_CONCERNS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownActions.GET_PET_CONCERNS_SUCCESS:
      return {
        ...state,
        loading: false,
        pet_concerns: payload?.data,
      };
    case vetDropdownActions.GET_PET_CONCERNS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
