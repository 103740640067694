import * as vetActions from "./vetAction";
export const vetFeatureKey = "vetInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  vet_details: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case vetActions.CLEAR_VET_DETAILS:
      return {
        ...state,
        vet_details: {},
      };

    // add address
    case vetActions.GET_VET_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetActions.GET_VET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        vet_details: payload?.vetDetails,
      };
    case vetActions.GET_VET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
