import * as phonepePaymentActions from "./phonepePaymentAction";
export const phonepePaymentFeatureKey = "phonepePaymentInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  paymentStatusData: {},
  selectedPaymentMethod: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case phonepePaymentActions.GET_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case phonepePaymentActions.GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentStatusData: payload.data,
      };

    case phonepePaymentActions.GET_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get payment selection:
    case phonepePaymentActions.GET_PAYMENT_SELECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case phonepePaymentActions.GET_PAYMENT_SELECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPaymentMethod: payload.data,
      };

    case phonepePaymentActions.GET_PAYMENT_SELECTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
