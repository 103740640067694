import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Get Cart
export const CREATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST = "CREATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST";
export const CREATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS = "CREATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS";
export const CREATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE = "CREATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE";

// Update Rating
export const UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST = "UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST";
export const UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS = "UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS";
export const UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE = "UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE";

// Delete Rating
export const DELETE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST = "DELETE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST";
export const DELETE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS = "DELETE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS";
export const DELETE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE = "DELETE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE";

// Get All My Ratings for User
export const GET_ALL_MY_PRODUCT_RATINGS_REQUEST = "GET_ALL_MY_PRODUCT_RATINGS_REQUEST";
export const GET_ALL_MY_PRODUCT_RATINGS_SUCCESS = "GET_ALL_MY_PRODUCT_RATINGS_SUCCESS";
export const GET_ALL_MY_PRODUCT_RATINGS_FAILURE = "GET_ALL_MY_PRODUCT_RATINGS_FAILURE";

export const createReview = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST });
      const url = `${BASE_URL}/ratings/take/from/user/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Rated successfully");
      dispatch({ type: CREATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getAllRatingsOfUser = (userId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_MY_PRODUCT_RATINGS_REQUEST });
      const url = `${BASE_URL}/ratings/for/user/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          userId,
        },
      });
      dispatch({ type: GET_ALL_MY_PRODUCT_RATINGS_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_ALL_MY_PRODUCT_RATINGS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const updateReview = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST });
      const url = `${BASE_URL}/ratings/update/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success(" Updated successFully");
      dispatch({ type: UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const deleteReview = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST });
      const url = `${BASE_URL}/ratings/delete/${id}/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("rate SuccessFully");
      dispatch({ type: DELETE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE, payload: error.message });
    }
  };
};
