import * as reminderActions from "./reminderAction";
export const reminderFeatureKey = "reminderInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  reminderProducts: [],
  remiderProductCount:undefined,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case reminderActions.GET_ALL_REMINDER_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case reminderActions.GET_ALL_REMINDER_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reminderProducts: payload.data,
        remiderProductCount:payload.count
      };
    case reminderActions.GET_ALL_REMINDER_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      // set reminder:
      case reminderActions.SET_MEDICINE_TO_REMINDER_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case reminderActions.SET_MEDICINE_TO_REMINDER_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case reminderActions.SET_MEDICINE_TO_REMINDER_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        }
    default:
      return state;
  }
};
