import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ADDRESS_LIST_REQUEST = "ADDRESS_LIST_REQUEST";
export const ADDRESS_LIST_SUCCESS = "ADDRESS_LIST_SUCCESS";
export const ADDRESS_LIST_FAILURE = "ADDRESS_LIST_FAILURE";

export const SINGLE_ADDRESS_REQUEST = "SINGLE_ADDRESS_REQUEST";
export const SINGLE_ADDRESS_SUCCESS = "SINGLE_ADDRESS_SUCCESS";
export const SINGLE_ADDRESS_FAILURE = "SINGLE_ADDRESS_FAILURE";

export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

export const EDIT_ADDRESS_REQUEST = "EDIT_ADDRESS_REQUEST";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILURE = "EDIT_ADDRESS_FAILURE";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

export const getAddressList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADDRESS_LIST_REQUEST });
      const url = `${BASE_URL}/address/get/all/address/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: ADDRESS_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: ADDRESS_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getSingleAddress = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SINGLE_ADDRESS_REQUEST });
      const url = `${BASE_URL}/address/get/single/address/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: SINGLE_ADDRESS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: SINGLE_ADDRESS_FAILURE, payload: error.message });
    }
  };
};

export const addAddress = (data, clearData) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: "IS_ADDRESS_UPDATE" });
      dispatch({ type: ADD_ADDRESS_REQUEST });
      const url = `${BASE_URL}/address/add/address/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: ADD_ADDRESS_SUCCESS, payload: response.data });
      clearData();
      toast.success("Address Added Successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: ADD_ADDRESS_FAILURE, payload: error.message });
    }
  };
};

export const editAddress = (id, data, clearData) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: "IS_ADDRESS_UPDATE" });
      dispatch({ type: EDIT_ADDRESS_REQUEST });
      const url = `${BASE_URL}/address/update/address/${id}/v1`;
      if(data.location){
        data.location.type="Point";
      }
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: EDIT_ADDRESS_SUCCESS, payload: response.data });
      clearData();
      toast.success("Address Updated Successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: EDIT_ADDRESS_FAILURE, payload: error.message });
    }
  };
};

export const deleteAddress = (addressId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ADDRESS_UPDATE" });
      dispatch({ type: DELETE_ADDRESS_REQUEST });
      const url = `${BASE_URL}/address/delete/address/${addressId}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: DELETE_ADDRESS_SUCCESS, payload: response.data });
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_ADDRESS_FAILURE, payload: error.message });
    }
  };
};
