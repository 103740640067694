import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// GET all booking prescrption:
export const GET_ALL_REMINDER_PRODUCTS_REQUEST = "GET_ALL_REMINDER_PRODUCTS_REQUEST";
export const GET_ALL_REMINDER_PRODUCTS_SUCCESS = "GET_ALL_REMINDER_PRODUCTS_SUCCESS";
export const GET_ALL_REMINDER_PRODUCTS_FAILURE = "GET_ALL_REMINDER_PRODUCTS_FAILURE";

// set reminder:
export const SET_MEDICINE_TO_REMINDER_REQUEST = "SET_MEDICINE_TO_REMINDER_REQUEST";
export const SET_MEDICINE_TO_REMINDER_SUCCESS = "SET_MEDICINE_TO_REMINDER_SUCCESS";
export const SET_MEDICINE_TO_REMINDER_FAILURE = "SET_MEDICINE_TO_REMINDER_FAILURE";

// Get all prescriptions:
export const getAllReminderProducts = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_REMINDER_PRODUCTS_REQUEST });
      const url = `${BASE_URL}/reminder/get/all/products/from/prescription/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params:{
          page
        }
      });
      dispatch({
        type: GET_ALL_REMINDER_PRODUCTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_REMINDER_PRODUCTS_FAILURE,
        payload: error.message,
      });
    }
  };
};


export const setReminder = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_MEDICINE_TO_REMINDER_REQUEST });
      const url = `${BASE_URL}/reminder/set/medicine/to/reminder/v1`;
      const response = await axios.put(url,data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({
        type: SET_MEDICINE_TO_REMINDER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_MEDICINE_TO_REMINDER_FAILURE,
        payload: error.message,
      });
    }
  };
};
