import React, { useEffect } from "react";
import DoctorInfoCard from "../components/common/DoctorInfoCard";
import * as vetActions from "../redux/vet/vetAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import AboutVeterian from "../components/common/AboutVeterian";

const DoctorDetailsPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const vet = useSelector((state) => state.vetInfo.vet_details);

  // get Single Vet Details
  useEffect(() => {
    if (params.id) {
      dispatch(vetActions.getVetDetails(params.id));
    }
  }, [params.id]);
  return (
    <Container>
      {" "}
      <div style={{marginTop:"4vh"}}>
      <DoctorInfoCard vet={vet} consultationInfo={""} />
      <AboutVeterian vet={vet} consultationInfo={""} />
      </div>
    </Container>
  );
};

export default DoctorDetailsPage;
