import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// get Blood test Dropdown
export const GET_PHARMACY_HOMEPAGE_DATA_REQUEST = "GET_PHARMACY_HOMEPAGE_DATA_REQUEST";
export const GET_PHARMACY_HOMEPAGE_DATA_SUCCESS = "GET_PHARMACY_HOMEPAGE_DATA_SUCCESS";
export const GET_PHARMACY_HOMEPAGE_DATA_FAILURE = "GET_PHARMACY_HOMEPAGE_DATA_FAILURE";

// get Filter Products By Concern
export const GET_FILTER_PRODUCTS_BY_CONCERN_REQUEST = "GET_FILTER_PRODUCTS_BY_CONCERN_REQUEST";
export const GET_FILTER_PRODUCTS_BY_CONCERN_SUCCESS = "GET_FILTER_PRODUCTS_BY_CONCERN_SUCCESS";
export const GET_FILTER_PRODUCTS_BY_CONCERN_FAILURE = "GET_FILTER_PRODUCTS_BY_CONCERN_FAILURE";

export const getPharmacyPageData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PHARMACY_HOMEPAGE_DATA_REQUEST });
      const url = `${BASE_URL}/product/get/pharmacy/page/data/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: data,
      });
      dispatch({
        type: GET_PHARMACY_HOMEPAGE_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_PHARMACY_HOMEPAGE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getFilterProductsByConcern = (concern, locationState) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FILTER_PRODUCTS_BY_CONCERN_REQUEST });
      // alert(data)
      const url = `${BASE_URL}/product/get/filter/products/by/concern/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: { concern, ...locationState },
      });
      dispatch({
        type: GET_FILTER_PRODUCTS_BY_CONCERN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_FILTER_PRODUCTS_BY_CONCERN_FAILURE,
        payload: error.message,
      });
    }
  };
};
