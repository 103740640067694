import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Get Cart
export const VERIFY_USER_REFER_ID_REQUEST = "VERIFY_USER_REFER_ID_REQUEST";
export const VERIFY_USER_REFER_ID_SUCCESS = "VERIFY_USER_REFER_ID_SUCCESS";
export const VERIFY_USER_REFER_ID_FAILURE = "VERIFY_USER_REFER_ID_FAILURE";

export const verifyUserReferId = (referralId, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_USER_REFER_ID_REQUEST });
      const url = `${BASE_URL}/refer/user/verify/refer/id/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          referralId,
        },
      });
      toast("Coupon applied successfully");
      dispatch({ type: VERIFY_USER_REFER_ID_SUCCESS, payload: response?.data });
      navigate('/');
    } catch (error) {
      console.error(error);
      dispatch({ type: VERIFY_USER_REFER_ID_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
