import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_ALL_VIDIOS_REQUEST = "GET_ALL_VIDIOS_REQUEST";
export const GET_ALL_VIDIOS_SUCCESS = "GET_ALL_VIDIOS_SUCCESS";
export const GET_ALL_VIDIOS_FAILURE = "GET_ALL_VIDIOS_FAILURE";

export const GET_ALL_SUGGESTION_VIDIOS_REQUEST = "GET_ALL_SUGGESTION_VIDIOS_REQUEST";
export const GET_ALL_SUGGESTION_VIDIOS_SUCCESS = "GET_ALL_SUGGESTION_VIDIOS_SUCCESS";
export const GET_ALL_SUGGESTION_VIDIOS_FAILURE = "GET_ALL_SUGGESTION_VIDIOS_FAILURE";

export const GET_AGORE_VIDEOS_TOKEN_REQUEST = "GET_AGORE_VIDEOS_TOKEN_REQUEST";
export const GET_AGORE_VIDEOS_TOKEN_SUCCESS = "GET_AGORE_VIDEOS_TOKEN_SUCCESS";
export const GET_AGORE_VIDEOS_TOKEN_FAILURE = "GET_AGORE_VIDEOS_TOKEN_FAILURE";

// Upadte views:
export const UPDATE_VIDEO_VIEWS_REQUEST = "UPDATE_VIDEO_VIEWS_REQUEST";
export const UPDATE_VIDEO_VIEWS_SUCCESS = "UPDATE_VIDEO_VIEWS_SUCCESS";
export const UPDATE_VIDEO_VIEWS_FAILURE = "UPDATE_VIDEO_VIEWS_FAILURE";

// Comment on video
export const COMMENT_ON_VIDEO_REQUEST = "COMMENT_ON_VIDEO_REQUEST";
export const COMMENT_ON_VIDEO_SUCCESS = "COMMENT_ON_VIDEO_SUCCESS";
export const COMMENT_ON_VIDEO_FAILURE = "COMMENT_ON_VIDEO_FAILURE";

// Get all comment of videos
export const GET_ALL_COMMENTS_OF_VIDEO_REQUEST = "GET_ALL_COMMENTS_OF_VIDEO_REQUEST";
export const GET_ALL_COMMENTS_OF_VIDEO_SUCCESS = "GET_ALL_COMMENTS_OF_VIDEO_SUCCESS";
export const GET_ALL_COMMENTS_OF_VIDEO_FAILURE = "GET_ALL_COMMENTS_OF_VIDEO_FAILURE";

// Like video
export const LIKE_VIDEO_REQUEST = "LIKE_VIDEO_REQUEST";
export const LIKE_VIDEO_SUCCESS = "LIKE_VIDEO_SUCCESS";
export const LIKE_VIDEO_FAILURE = "LIKE_VIDEO_FAILURE";

// update comment
export const UPDATE_VIDEO_COMMENT_REQUEST = "UPDATE_VIDEO_COMMENT_REQUEST";
export const UPDATE_VIDEO_COMMENT_SUCCESS = "UPDATE_VIDEO_COMMENT_SUCCESS";
export const UPDATE_VIDEO_COMMENT_FAILURE = "UPDATE_VIDEO_COMMENT_FAILURE";



// delete comment 
export const DELETE_VIDEO_COMMENT_REQUEST = "DELETE_VIDEO_COMMENT_REQUEST";
export const DELETE_VIDEO_COMMENT_SUCCESS = "DELETE_VIDEO_COMMENT_SUCCESS";
export const DELETE_VIDEO_COMMENT_FAILURE = "DELETE_VIDEO_COMMENT_FAILURE";

export const getAllVidios = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_VIDIOS_REQUEST });
      const url = `${BASE_URL}/user/get/all/vidios/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({ type: GET_ALL_VIDIOS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_VIDIOS_FAILURE, payload: error.message });
    }
  };
};

export const updateVideoViews = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VIDEO_VIEWS_FAILURE });
      const url = `${BASE_URL}/user/update/video/views/${id}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: UPDATE_VIDEO_VIEWS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: UPDATE_VIDEO_VIEWS_FAILURE, payload: error.message });
    }
  };
};

export const getAllSuggestionVidios = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_SUGGESTION_VIDIOS_REQUEST });
      const url = `${BASE_URL}/user/get/all/vidios/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({ type: GET_ALL_SUGGESTION_VIDIOS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_SUGGESTION_VIDIOS_FAILURE, payload: error.message });
    }
  };
};

export const getAgoraVideoToken = (channel, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_REQUEST });
      const url = `${BASE_URL}/agoraToken/rtc/${channel}/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_FAILURE, payload: error.message });
    }
  };
};

export const endVideoCallToken = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_REQUEST });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_FAILURE, payload: error.message });
    }
  };
};

export const commentOnVideo = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: COMMENT_ON_VIDEO_REQUEST });
      const url = `${BASE_URL}/video/comment/to/video/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: COMMENT_ON_VIDEO_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: COMMENT_ON_VIDEO_FAILURE, payload: error.message });
    }
  };
};

export const getAllComments = (videoId, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_COMMENTS_OF_VIDEO_REQUEST });
      const url = `${BASE_URL}/video/get/all/comments/${videoId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page,
        },
      });
      dispatch({ type: GET_ALL_COMMENTS_OF_VIDEO_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_COMMENTS_OF_VIDEO_FAILURE, payload: error.message });
    }
  };
};

export const likeVideo = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LIKE_VIDEO_REQUEST });
      const url = `${BASE_URL}/video/like/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: LIKE_VIDEO_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: LIKE_VIDEO_FAILURE, payload: error.message });
    }
  };
};

export const updateVideoComment = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VIDEO_COMMENT_REQUEST });
      const url = `${BASE_URL}/video/update/comment/of/video/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_VIDEO_COMMENT_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: UPDATE_VIDEO_COMMENT_FAILURE, payload: error.message });
    }
  };
};

export const deleteVideoComment = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_VIDEO_COMMENT_REQUEST });
      const url = `${BASE_URL}/video/delete/comment/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_VIDEO_COMMENT_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: DELETE_VIDEO_COMMENT_FAILURE, payload: error.message });
    }
  };
};