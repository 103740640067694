import * as pharmacyActions from "./pharmacyAction";
export const pharmacyFeatureKey = "pharmacyInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  pagedata: [],
  filterProductByConcern: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get Schedule Vets List
    case pharmacyActions.GET_PHARMACY_HOMEPAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case pharmacyActions.GET_PHARMACY_HOMEPAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        pageData: payload,
      };
    case pharmacyActions.GET_PHARMACY_HOMEPAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      // filter Products By Concern
      case pharmacyActions.GET_FILTER_PRODUCTS_BY_CONCERN_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case pharmacyActions.GET_FILTER_PRODUCTS_BY_CONCERN_SUCCESS:
        return {
          ...state,
          loading: false,
          filterProductByConcern: payload,
        };
      case pharmacyActions.GET_FILTER_PRODUCTS_BY_CONCERN_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };
    default:
      return state;
  }
};
