import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_PET_CONCERNS_REQUEST = "GET_PET_CONCERNS_REQUEST";
export const GET_PET_CONCERNS_SUCCESS = "GET_PET_CONCERNS_SUCCESS";
export const GET_PET_CONCERNS_FAILURE = "GET_PET_CONCERNS_FAILURE";

export const getPetConcernsList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PET_CONCERNS_REQUEST });
      const url = `${BASE_URL}/vet/get/health/concerns/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_PET_CONCERNS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PET_CONCERNS_FAILURE, payload: error.message });
    }
  };
};
