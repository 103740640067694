import * as ratingAction from "./ratingAction";

export const ratingFeatureKey = "ratingInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  addCart: {},
  myCart: [],
  allRatings: [],
  isUpdated:{}
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get Cart
    case ratingAction.CREATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ratingAction.CREATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        // myCart: payload,
      };

    case ratingAction.CREATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update Review And Rate
    case ratingAction.UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ratingAction.UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated:payload
        // myCart: payload,
      };

    case ratingAction.UPDATE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete Rating
    case ratingAction.DELETE_RATE_AND_REVIEW_FOR_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ratingAction.DELETE_RATE_AND_REVIEW_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        // myCart: payload,
      };

    case ratingAction.DELETE_RATE_AND_REVIEW_FOR_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get All Ratings
    case ratingAction.GET_ALL_MY_PRODUCT_RATINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ratingAction.GET_ALL_MY_PRODUCT_RATINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        allRatings: payload?.data,
      };

    case ratingAction.GET_ALL_MY_PRODUCT_RATINGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
