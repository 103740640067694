import * as productActions from "./productAction";
export const productFeatureKey = "productInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  productData: "",
  allProducts: [],
  productsCount: null,
  productCategoryList: [],
  productSubCategoyList: [],
  concernList: [],
  productPageData: {},
  shopList: [],
  shopListCount: null,
  storeInfo: {},
  pharmacySubCategories: [],
  productPageDataloading: false,
  allProductsByTagloading: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case productActions.GET_SEE_ALL_RANDOM_PRODUCTS_REQUEST:
      return {
        ...state,
        allProducts: [],
        loading: true,
      };
    case productActions.GET_SEE_ALL_RANDOM_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: payload.data,
        productsCount: payload.count,
        loading: false,
      };
    case productActions.GET_SEE_ALL_RANDOM_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get pharmacy subcategories:
    case productActions.GET_ALL_PHARMACY_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.GET_ALL_PHARMACY_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        pharmacySubCategories: payload?.data,
        loading: false,
      };
    case productActions.GET_ALL_PHARMACY_SUBCATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get Above Buy Items:
    case productActions.GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_REQUEST:
      return {
        ...state,
        allProducts: [],
        loading: true,
      };
    case productActions.GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_SUCCESS:
      return {
        ...state,
        allProducts: payload.data,
        productsCount: payload.count,
        loading: false,
      };
    case productActions.GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get shop list:
    case productActions.GET_NEARBY_SHOP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.GET_NEARBY_SHOP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shopList: payload.data,
        shopListCount: payload.count,
      };
    case productActions.GET_NEARBY_SHOP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get products by shop:
    case productActions.GET_PRODUCTS_BY_SHOP_REQUEST:
      return {
        ...state,
        allProducts: [],
        loading: true,
      };
    case productActions.GET_PRODUCTS_BY_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        allProducts: payload.data,
        storeInfo: payload.storeInfo,
        productsCount: payload.count,
      };
    case productActions.GET_PRODUCTS_BY_SHOP_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get concern list
    case productActions.GET_CONCERNS_LIST_FOR_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.GET_CONCERNS_LIST_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        concernList: payload.data,
      };
    case productActions.GET_CONCERNS_LIST_FOR_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get product page data:
    case productActions.GET_PRODUCT_PAGE_DATA_REQUEST:
      return {
        ...state,
        productPageData: {},
        productPageDataloading: true,
      };

    case productActions.GET_PRODUCT_PAGE_DATA_SUCCESS:
      return {
        ...state,
        productPageDataloading: false,
        productPageData: payload,
      };
    case productActions.GET_PRODUCT_PAGE_DATA_FAILURE:
      return {
        ...state,
        productPageDataloading: false,
        errorMsg: payload,
      };

    // Get product categories:
    case productActions.GET_PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        productCategoryList: payload.data,
        loading: false,
      };
    case productActions.GET_PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get product sub-categories:
    case productActions.GET_PRODUCT_SUB_CATEGORIES_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case productActions.GET_PRODUCT_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        // loading: false,
        productSubCategoyList: payload.data,
      };
    case productActions.GET_PRODUCT_SUB_CATEGORIES_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
      };

    // get Product details
    case productActions.GET_SINGLE_PRODUCT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.GET_SINGLE_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        productData: payload.data,
      };
    case productActions.GET_SINGLE_PRODUCT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get All Products

    // get All Best Seller Prodcuts
    case productActions.GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        allProducts: [],
        loading: true,
      };
    case productActions.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: payload.data,
        loading: false,
      };
    case productActions.GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,

        errorMsg: payload,
      };

    // get Similar Products
    case productActions.GET_SIMILAR_PRODUCTS_REQUEST:
      return {
        ...state,
        allProducts: [],
        loading: true,
      };
    case productActions.GET_SIMILAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: payload.data,
        productsCount: payload.count,
        loading: false,
      };
    case productActions.GET_SIMILAR_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get All Top Deals
    case productActions.GET_ALL_PRODUCTS_BY_TAG_REQUEST:
      return {
        ...state,
        allProducts: [],
        allProductsByTagloading: true,
      };
    case productActions.GET_ALL_PRODUCTS_BY_TAG_SUCCESS:
      return {
        ...state,
        allProducts: payload.data,
        productsCount: payload.count,
        allProductsByTagloading: false,
      };

    case productActions.GET_ALL_PRODUCTS_BY_TAG_FAILURE:
      return {
        ...state,
        allProductsByTagloading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
