import * as petCategoryActions from "./petCategoryAction";
export const petCategoryFeatureKey = "petCategoryInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  petCategories: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case petCategoryActions.GET_PET_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petCategoryActions.GET_PET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        petCategories: payload?.data,
      };
    case petCategoryActions.GET_PET_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
