import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const HOME_PAGE_DATA_REQUEST = "HOME_PAGE_DATA_REQUEST";
export const HOME_PAGE_DATA_SUCCESS = "HOME_PAGE_DATA_SUCCESS";
export const HOME_PAGE_DATA_FAILURE = "HOME_PAGE_DATA_FAILURE";

// Home search api
export const HOME_SEARCH_BAR_DATA_REQUEST = "HOME_SEARCH_BAR_DATA_REQUEST";
export const HOME_SEARCH_BAR_DATA_SUCCESS = "HOME_SEARCH_BAR_DATA_SUCCESS";
export const HOME_SEARCH_BAR_DATA_FAILURE = "HOME_SEARCH_BAR_DATA_FAILURE";


// delete search data
export const DELETE_SEARCH_DATA_REQUEST = "DELETE_SEARCH_DATA_REQUEST";
export const DELETE_SEARCH_DATA_SUCCESS = "DELETE_SEARCH_DATA_SUCCESS";
export const DELETE_SEARCH_DATA_FAILURE = "DELETE_SEARCH_DATA_FAILURE";

export const getHomeData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HOME_PAGE_DATA_REQUEST });
      const url = `${BASE_URL}/home/get/home/page/info/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: HOME_PAGE_DATA_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: HOME_PAGE_DATA_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const homeScreenSearchApi = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HOME_SEARCH_BAR_DATA_REQUEST });
      const url = `${BASE_URL}/user/search/bar/result/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: { searchInput: searchQuery },
      });
      dispatch({ type: HOME_SEARCH_BAR_DATA_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: HOME_SEARCH_BAR_DATA_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const deleteRecentSearch = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SEARCH_DATA_REQUEST });
      const url = `${BASE_URL}/user/delete/recent/searches/result/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_SEARCH_DATA_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_SEARCH_DATA_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
