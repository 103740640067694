import axios from "axios";
import { toast } from "react-toastify";
import * as userActions from "../user/userAction";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Get Cart
export const GET_MY_CART_REQUEST = "GET_MY_CART_REQUEST";
export const GET_MY_CART_SUCCESS = "GET_MY_CART_SUCCESS";
export const GET_MY_CART_FAILURE = "GET_MY_CART_FAILURE";

// Add To Cart
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

// Add All products to Cart
export const ADD_ALL_TO_CART_REQUEST = "ADD_ALL_TO_CART_REQUEST";
export const ADD_ALL_TO_CART_SUCCESS = "ADD_ALL_TO_CART_SUCCESS";
export const ADD_ALL_TO_CART_FAILURE = "ADD_ALL_TO_CART_FAILURE";

// Update Cart Item
export const UPDATE_CART_ITEM_REQUEST = "UPDATE_CART_ITEM_REQUEST";
export const UPDATE_CART_ITEM_SUCCESS = "UPDATE_CART_ITEM_SUCCESS";
export const UPDATE_CART_ITEM_FAILURE = "UPDATE_CART_ITEM_FAILURE";

// Delete product from cart:
export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAILURE = "REMOVE_FROM_CART_FAILURE";

export const getCartData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_CART_REQUEST });
      const url = `${BASE_URL}/cart/get/my/cart/products/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_MY_CART_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_MY_CART_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const updateCartData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CART_ITEM_REQUEST });
      const url = `${BASE_URL}/cart/update/product/in/cart/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_CART_ITEM_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_CART_ITEM_FAILURE, payload: error.message });
    }
  };
};

export const addToCart = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_TO_CART_REQUEST });
      const url = `${BASE_URL}/cart/add/products/to/cart/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: ADD_TO_CART_SUCCESS, payload: response?.data });
      // navigate("/cart");
      dispatch(userActions.getUser());
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: ADD_TO_CART_FAILURE, payload: error.message });
    }
  };
};

export const addAllProductsToCart = (allProducts, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_ALL_TO_CART_REQUEST });
      const url = `${BASE_URL}/cart/add/all/products/to/cart/v1`;
      const response = await axios.post(
        url,
        { allProducts },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: ADD_ALL_TO_CART_SUCCESS, payload: response?.data });
      // navigate("/cart");
      dispatch(userActions.getUser());
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: ADD_ALL_TO_CART_FAILURE, payload: error.message });
    }
  };
};

export const removeFromCart = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REMOVE_FROM_CART_REQUEST });
      const url = `${BASE_URL}/cart/delete/product/from/cart/${id}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: REMOVE_FROM_CART_SUCCESS, payload: response?.data });
      // navigate("/cart")
      // toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: REMOVE_FROM_CART_FAILURE, payload: error.message });
    }
  };
};
