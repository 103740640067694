import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_VET_DETAILS_REQUEST = "GET_VET_DETAILS_REQUEST";
export const GET_VET_DETAILS_SUCCESS = "GET_VET_DETAILS_SUCCESS";
export const GET_VET_DETAILS_FAILURE = "GET_VET_DETAILS_FAILURE";

// clear empty vetdetails:
export const CLEAR_VET_DETAILS = "CLEAR_VET_DETAILS";

export const clearVetDetails = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_VET_DETAILS });
  };
};

export const getVetDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VET_DETAILS_REQUEST });
      const url = `${BASE_URL}/user/get/vet/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({ type: GET_VET_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      // console.error(error);
      // toast.error(error.message);
      dispatch({ type: GET_VET_DETAILS_FAILURE, payload: error.message });
    }
  };
};
