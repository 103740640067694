import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// get Blood test Dropdown
export const GET_SINGLE_PRODUCT_DATA_REQUEST = "GET_SINGLE_PRODUCT_DATA_REQUEST";
export const GET_SINGLE_PRODUCT_DATA_SUCCESS = "GET_SINGLE_PRODUCT_DATA_SUCCESS";
export const GET_SINGLE_PRODUCT_DATA_FAILURE = "GET_SINGLE_PRODUCT_DATA_FAILURE";

// get All Products
export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

// Get product page by category
export const GET_PRODUCT_PAGE_DATA_REQUEST = "GET_PRODUCT_PAGE_DATA_REQUEST";
export const GET_PRODUCT_PAGE_DATA_SUCCESS = "GET_PRODUCT_PAGE_DATA_SUCCESS";
export const GET_PRODUCT_PAGE_DATA_FAILURE = "GET_PRODUCT_PAGE_DATA_FAILURE";

// Get concerns for product:
export const GET_CONCERNS_LIST_FOR_PRODUCT_REQUEST = "GET_CONCERNS_LIST_FOR_PRODUCT_REQUEST";
export const GET_CONCERNS_LIST_FOR_PRODUCT_SUCCESS = "GET_CONCERNS_LIST_FOR_PRODUCT_SUCCESS";
export const GET_CONCERNS_LIST_FOR_PRODUCT_FAILURE = "GET_CONCERNS_LIST_FOR_PRODUCT_FAILURE";

// Get product categories:
export const GET_PRODUCT_CATEGORIES_REQUEST = "GET_PRODUCT_CATEGORIES_REQUEST";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FAILURE = "GET_PRODUCT_CATEGORIES_FAILURE";

// Get product subcategories:
export const GET_PRODUCT_SUB_CATEGORIES_REQUEST = "GET_PRODUCT_SUB_CATEGORIES_REQUEST";
export const GET_PRODUCT_SUB_CATEGORIES_SUCCESS = "GET_PRODUCT_SUB_CATEGORIES_SUCCESS";
export const GET_PRODUCT_SUB_CATEGORIES_FAILURE = "GET_PRODUCT_SUB_CATEGORIES_FAILURE";

// get Similar Products Info
export const GET_SIMILAR_PRODUCTS_REQUEST = "GET_SIMILAR_PRODUCTS_REQUEST";
export const GET_SIMILAR_PRODUCTS_SUCCESS = "GET_SIMILAR_PRODUCTS_SUCCESS";
export const GET_SIMILAR_PRODUCTS_FAILURE = "GET_SIMILAR_PRODUCTS_FAILURE";

// get All Top Deals Products
export const GET_ALL_PRODUCTS_BY_TAG_REQUEST = "GET_ALL_PRODUCTS_BY_TAG_REQUEST";
export const GET_ALL_PRODUCTS_BY_TAG_SUCCESS = "GET_ALL_PRODUCTS_BY_TAG_SUCCESS";
export const GET_ALL_PRODUCTS_BY_TAG_FAILURE = "GET_ALL_PRODUCTS_BY_TAG_FAILURE";

// get All Best Sellers Products
export const GET_ALL_BEST_SELLER_PRODUCTS_REQUEST = "GET_ALL_BEST_SELLER_PRODUCTS_REQUEST";
export const GET_ALL_BEST_SELLER_PRODUCTS_SUCCESS = "GET_ALL_BEST_SELLER_PRODUCTS_SUCCESS";
export const GET_ALL_BEST_SELLER_PRODUCTS_FAILURE = "GET_ALL_BEST_SELLER_PRODUCTS_FAILURE";

// get All Allergy Relif Produts
export const GET_ALL_ALLERGY_RELIEF_PRODUCTS_REQUEST = "GET_ALL_ALLERGY_RELIEF_PRODUCTS_REQUEST";
export const GET_ALL_ALLERGY_RELIEF_PRODUCTS_SUCCESS = "GET_ALL_ALLERGY_RELIEF_PRODUCTS_SUCCESS";
export const GET_ALL_ALLERGY_RELIEF_PRODUCTS_FAILURE = "GET_ALL_ALLERGY_RELIEF_PRODUCTS_FAILURE";

// get All Anxius Pet Products
export const GET_ALL_ANXIOUS_PET_PRODUCTS_REQUEST = "GET_ALL_ANXIOUS_PET_PRODUCTS_REQUEST";
export const GET_ALL_ANXIOUS_PET_PRODUCTS_SUCCESS = "GET_ALL_ANXIOUS_PET_PRODUCTS_SUCCESS";
export const GET_ALL_ANXIOUS_PET_PRODUCTS_FAILURE = "GET_ALL_ANXIOUS_PET_PRODUCTS_FAILURE";

// get All Curate Dental Kit
export const GET_ALL_CURATE_DENTAL_KIT_PRODUCTS_REQUEST = "GET_ALL_CURATE_DENTAL_KIT_PRODUCTS_REQUEST";
export const GET_ALL_CURATE_DENTAL_KIT_PRODUCTS_SUCCESS = "GET_ALL_CURATE_DENTAL_KIT_PRODUCTS_SUCCESS";
export const GET_ALL_CURATE_DENTAL_KIT_PRODUCTS_FAILURE = "GET_ALL_CURATE_DENTAL_KIT_PRODUCTS_FAILURE";

// Get All about wellness
export const GET_ALL_ABOUT_WELLNESS_PET_PRODUCTS_REQUEST = "GET_ALL_ABOUT_WELLNESS_PET_PRODUCTS_REQUEST";
export const GET_ALL_ABOUT_WELLNESS_PET_PRODUCTS_SUCCESS = "GET_ALL_ABOUT_WELLNESS_PET_PRODUCTS_SUCCESS";
export const GET_ALL_ABOUT_WELLNESS_PET_PRODUCTS_FAILURE = "GET_ALL_ABOUT_WELLNESS_PET_PRODUCTS_FAILURE";

// Get All Eye And Ear products
export const GET_ALL_EYE_AND_EAR_PET_PRODUCTS_REQUEST = "GET_ALL_EYE_AND_EAR_PET_PRODUCTS_REQUEST";
export const GET_ALL_EYE_AND_EAR_PET_PRODUCTS_SUCCESS = "GET_ALL_EYE_AND_EAR_PET_PRODUCTS_SUCCESS";
export const GET_ALL_EYE_AND_EAR_PET_PRODUCTS_FAILURE = "GET_ALL_EYE_AND_EAR_PET_PRODUCTS_FAILURE";

// Shop:
// Get products by shop:
export const GET_PRODUCTS_BY_SHOP_REQUEST = "GET_PRODUCTS_BY_SHOP_REQUEST";
export const GET_PRODUCTS_BY_SHOP_SUCCESS = "GET_PRODUCTS_BY_SHOP_SUCCESS";
export const GET_PRODUCTS_BY_SHOP_FAILURE = "GET_PRODUCTS_BY_SHOP_FAILURE";

// Get nearby shops:
export const GET_NEARBY_SHOP_LIST_REQUEST = "GET_NEARBY_SHOP_LIST_REQUEST";
export const GET_NEARBY_SHOP_LIST_SUCCESS = "GET_NEARBY_SHOP_LIST_SUCCESS";
export const GET_NEARBY_SHOP_LIST_FAILURE = "GET_NEARBY_SHOP_LIST_FAILURE";

// Get Other customer buys items:
export const GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_REQUEST = "GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_REQUEST";
export const GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_SUCCESS = "GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_SUCCESS";
export const GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_FAILURE = "GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_FAILURE";

// Get all random products :
export const GET_SEE_ALL_RANDOM_PRODUCTS_REQUEST = "GET_SEE_ALL_RANDOM_PRODUCTS_REQUEST";
export const GET_SEE_ALL_RANDOM_PRODUCTS_SUCCESS = "GET_SEE_ALL_RANDOM_PRODUCTS_SUCCESS";
export const GET_SEE_ALL_RANDOM_PRODUCTS_FAILURE = "GET_SEE_ALL_RANDOM_PRODUCTS_FAILURE";

// Get all pharmacy subcategories:
export const GET_ALL_PHARMACY_SUBCATEGORIES_REQUEST = "GET_ALL_PHARMACY_SUBCATEGORIES_REQUEST";
export const GET_ALL_PHARMACY_SUBCATEGORIES_SUCCESS = "GET_ALL_PHARMACY_SUBCATEGORIES_SUCCESS";
export const GET_ALL_PHARMACY_SUBCATEGORIES_FAILURE = "GET_ALL_PHARMACY_SUBCATEGORIES_FAILURE";

export const getNearbyShopList = (page) => {
  return async (dispatch) => {
    try {
      const location = localStorage.getItem("location");
      const { lat, lng } = JSON.parse(location);

      dispatch({ type: GET_NEARBY_SHOP_LIST_REQUEST });
      const url = `${BASE_URL}/product/get/nearby/shop/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: { ...page, lat, lng },
      });
      dispatch({
        type: GET_NEARBY_SHOP_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_NEARBY_SHOP_LIST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductsByShop = (data, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCTS_BY_SHOP_REQUEST });
      const url = `${BASE_URL}/product/get/product/list/by/shop/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: data,
      });
      dispatch({
        type: GET_PRODUCTS_BY_SHOP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_BY_SHOP_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getConcernsListForProduct = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONCERNS_LIST_FOR_PRODUCT_REQUEST });
      const url = `${BASE_URL}/product/get/concerns/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_CONCERNS_LIST_FOR_PRODUCT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CONCERNS_LIST_FOR_PRODUCT_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductCategories = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCT_CATEGORIES_REQUEST });
      const url = `${BASE_URL}/product/get/all/product/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_PRODUCT_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_CATEGORIES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductSubCategories = (id) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: GET_PRODUCT_SUB_CATEGORIES_REQUEST });
      const url = `${BASE_URL}/product/get/all/product/sub/category/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      await dispatch({
        type: GET_PRODUCT_SUB_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_SUB_CATEGORIES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getPharmacySubCategories = (id) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: GET_ALL_PHARMACY_SUBCATEGORIES_REQUEST });
      const url = `${BASE_URL}/product/get/all/pharmacy/sub/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      await dispatch({
        type: GET_ALL_PHARMACY_SUBCATEGORIES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PHARMACY_SUBCATEGORIES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_PRODUCT_DATA_REQUEST });
      const url = `${BASE_URL}/product/get/single/product/${id}/info/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_SINGLE_PRODUCT_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_PRODUCT_DATA_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductByCategories = (queryData) => {
  return async (dispatch) => {
    try {
      const location = localStorage.getItem("location");
      const { lat, lng } = JSON.parse(location);
      dispatch({ type: GET_PRODUCT_PAGE_DATA_REQUEST });
      const url = `${BASE_URL}/product/get/product/by/product/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: { ...queryData, lat, lng },
      });
      dispatch({
        type: GET_PRODUCT_PAGE_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      // toast.error(error.response.data.message)
      dispatch({
        type: GET_PRODUCT_PAGE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getAllProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_PRODUCTS_REQUEST });
      const url = `${BASE_URL}/product/get/filter/product/by/category/v1`;

      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // alert(JSON.stringify(response))

      dispatch({
        type: GET_ALL_PRODUCTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_PRODUCTS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductListByTag = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_PRODUCTS_BY_TAG_REQUEST });
      const url = `${BASE_URL}/product/get/all/products/by/tag/v1`;

      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: data,
      });

      if (response?.data) {
        dispatch({
          type: GET_ALL_PRODUCTS_BY_TAG_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_PRODUCTS_BY_TAG_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getSimilarProducts = (queryData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SIMILAR_PRODUCTS_REQUEST });
      const url = `${BASE_URL}/product/get/similar/products/info/v1`;

      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: queryData,
      });
      // console.log(response)

      dispatch({
        type: GET_SIMILAR_PRODUCTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_SIMILAR_PRODUCTS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getProductsByCustomersBuyAboveItems = (queryData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_REQUEST });
      const url = `${BASE_URL}/product/get/product/customer/who/bought/above/items/v1`;

      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: queryData,
      });

      dispatch({
        type: GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_PRODUCTS_BY_CUSTOMER_MOST_BUY_ITEMS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getSeeAllRandomProducts = (queryData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SEE_ALL_RANDOM_PRODUCTS_REQUEST });
      const url = `${BASE_URL}/product/get/product/by/category/by/see/all/v1`;

      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: queryData,
      });

      dispatch({
        type: GET_SEE_ALL_RANDOM_PRODUCTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_SEE_ALL_RANDOM_PRODUCTS_FAILURE,
        payload: error.message,
      });
    }
  };
};
